<template>
  <div class="h-full">
    <loader :loading="loading" :backdrop="true" />
    <div class="form-row w-full">
      <FinalForm
        :submit="saveData"
        :initialValues="initialValues"
        class="w-full form-col-2 pr-2"
        ref="form"
      >
        <div class="stick bg-white pb-3 z-10">
          <div class="form-row">
            <label class="font-semibold" style="color: var(--highlightColor500)"
              >intrusion alarm</label
            >
          </div>
        </div>
        <div>
          <div class="form-row">
            <div class="text-title">alarm is</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="alarmRequired" />
            </button>
            <div class="text-title">required</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="alarmNotRequired" />
            </button>
            <div class="text-title">not required</div>
          </div>
          <div class="form-row">
            <div class="text-title">resisdent</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="residentDoesNeedPermit"
              />
            </button>
            <div class="text-title">does</div>
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="residentDoesNotNeedPermit"
              />
            </button>
            <div class="text-title">does not</div>
            <div class="text-title ml-4">
              have to obtain city permit for activation and use of intrusion
              alarm
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">if permit required, contact</div>
            <TextField name="permitRequiredContact" />
          </div>
          <div class="form-row">
            <div class="text-title">instructions</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="instructionsAttached" />
            </button>
            <div class="text-title">are attached</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="instructionsProvided" />
            </button>
            <div class="text-title">will be provided upon move in</div>
          </div>
          <div class="form-row">
            <div class="text-title">resisdent</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="residentWillActivateAlarm"
              />
            </button>
            <div class="text-title">will</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="residentWillNotActivateAlarm"
              />
            </button>
            <div class="text-title">
              will not have to make arrangements with independent alarm company
              to activate and maintain alarm system
            </div>
          </div>
          <div class="form-row">
            <div class="text-title">resisdent</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="residentChooseAlarmCompany"
              />
            </button>
            <div class="text-title">will choose alarm company</div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall
                type="checkbox"
                name="residentUseAlarmCompany"
              />
            </button>
            <div class="text-title">is required to use</div>
            <TextField name="alarmCompany" />
            <div class="text-title">as alarm company</div>
          </div>
          <div class="form-row">
            <div class="text-title">
              alarm system is repaired and maintained by
            </div>
            <TextField
              name="alarmMaintainedBy"
              class="ml-4"
            />
          </div>
          <div class="form-row">
            <div class="text-title">
              if intrusion alarm malfunctions, resident agrees to
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="contactAlarmCompany" />
            </button>
            <div class="text-title">
              contact intrusion alarm company immediately for repair or
            </div>
          </div>
          <div class="form-row">
            <button>
              <CheckboxInputSmall type="checkbox" name="contactUsForRepair" />
            </button>
            <div class="text-title">contact us immediately for repair</div>
          </div>
          <div class="form-row">
            <div class="text-title">cost of repair will be paid by</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="repairPaidByResident" />
            </button>
            <div class="text-title">resident</div>
            <button>
              <CheckboxInputSmall type="checkbox" name="repairPaidByUs" />
            </button>
            <div class="text-title">us</div>
          </div>
        </div>
      </FinalForm>
      <modal-footer :footer="footer" :primary="saveData"></modal-footer>
    </div>
  </div>
</template>

<script>
import ModalNavigation from "@/mixins/ModalNavigation";
import Loader from "@/components/ui/Loader";
import ModalFooter from "@/components/ui/modals/ModalFooter";
import TextField from "@/components/form/TextField";
import InitializeFormMixin from "@/components/form/InitializeFormMixin";
import { FinalForm } from "vue-final-form";
import CheckboxInputSmall from "@/components/form/CheckboxInputSmall";
export default {
  name: "IntrusionAlarm",
  components: {
    Loader,
    FinalForm,
    ModalFooter,
    TextField,
    CheckboxInputSmall,
  },
  mixins: [ModalNavigation, InitializeFormMixin],
  computed: {
    initialValues() {
      return {
        alarmRequired: "",
        alarmNotRequired: "",
        residentDoesNeedPermit: "",
        residentDoesNotNeedPermit: "",
        permitRequiredContact: "",
        instructionsAttached: "",
        instructionsProvided: "",
        residentWillActivateAlarm: "",
        residentWillNotActivateAlarm: "",
        residentChooseAlarmCompany: "",
        residentUseAlarmCompany: "",
        alarmCompany: "",
        alarmMaintainedBy: "",
        contactAlarmCompany: "",
        contactUsForRepair: "",
        repairPaidByResident: "",
        repairPaidByUs: "",
      };
    },
  },
  data() {
    return {
      loading: false,
      footer: {
        primaryButton: "save",
      },
      alarmRequired: "",
      alarmNotRequired: "",
      residentDoesNeedPermit: "",
      residentDoesNotNeedPermit: "",
      permitRequiredContact: "",
      instructionsAttached: "",
      instructionsProvided: "",
      residentWillActivateAlarm: "",
      residentWillNotActivateAlarm: "",
      residentChooseAlarmCompany: "",
      residentUseAlarmCompany: "",
      alarmCompany: "",
      alarmMaintainedBy: "",
      contactAlarmCompany: "",
      contactUsForRepair: "",
      repairPaidByResident: "",
      repairPaidByUs: "",
    };
  },
  methods: {
    saveData() {},
  },
};
</script>

<style scoped>
input {
  accent-color: var(--highlightColor500) !important;
}

.stick {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}
button {
  outline: none;
  box-shadow: none;
}
</style>
